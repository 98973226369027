
import Vue from "vue";

import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";
import PolicySignActivitiesTimelineItem from "./PolicySignActivitiesTimelineItem.vue";
interface PolicySignActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "accreditedBuilderActivitiesView",
  components: { PolicySignActivitiesTimelineItem },
  mixins: [ActivitiesMixin],

  data(): PolicySignActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  computed: {
    queryOverride(): any {
      return {
        name__in: `POLICY_SIGN_SUCCESS,POLICY_SIGN_FAILURE`.replace(/\s/g, ""),
        activityType: "PolicySignActivity"
      };
    }
  }
});
