<template>
  <div>
    <el-timeline-item
      v-if="content"
      :icon="icon"
      :type="type"
      :color="color"
      size="large"
      :timestamp="formatDate(activity.createdOn)"
    >
      <div class="flex gap-x-5">
        <div v-html="formattedActivityText" class=""></div>
        <div v-on:click="expandActivity = !expandActivity">
          <font-awesome-icon
            :icon="['fal', !expandActivity ? 'chevron-right' : 'chevron-down']"
            class="text-blue-700 hover:text-blue-400 mr-2"
          />
        </div>
      </div>
      <div v-if="expandActivity" v-html="processActivityData"></div>
    </el-timeline-item>
  </div>
</template>

<script>
import ActivitiesTimelineMixin from "@/components/ActivitiesView/ActivitiesTimelineMixin.vue";
export default {
  mixins: [ActivitiesTimelineMixin],
  props: {
    activity: {
      type: Object,
      required: true
    },
    accreditedBuilder: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      expandActivity: false
    };
  },
  computed: {
    processActivityData() {
      let html = "<div>";
      for (const key in this.activity.data) {
        html += `<p><span class='font-medium'>${key}</span>: ${this.activity.data[key]}</p>`;
      }
      html += "</div>";
      return html;
    }
  },
  watch: {
    activity: {
      async handler() {
        this.content = "";
        this.icon = "";
        this.type = "";
        this.link = {};
        this.color = "";
        switch (this.activity.name) {
          case "POLICY_SIGN_SUCCESS":
            this.generateReadableGeneric({
              content: `${this?.activity?.data?.insuredName} ${
                this?.activity?.data?.action === "read"
                  ? this?.activity?.data?.action
                  : this?.activity?.data?.action + "ed"
              } the subscriber agreement.`,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "POLICY_SIGN_FAILURE":
            this.generateReadableFailGeneric({
              content: `${this?.activity?.data?.insuredName} failed to ${this?.activity?.data?.action} subscriber agreement.`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
        }
      },
      immediate: true
    }
  }
};
</script>

<style></style>
